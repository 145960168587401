const pages = {
  offerings: [
    // {
    //   title: '+EV Bets',
    //   href: '/',
    // },
    {
      title: 'MLB',
      href: '/mlb',
    },
    {
      title: 'NHL',
      href: '/nhl',
    },
  ],
  mlb: [
    // {
    //   title: 'MLB SPs',
    //   href: '/mlb',
    // },
    {
      title: 'Moneylines',
      href: '/mlb/moneyline',
    },
    {
      title: 'Spreads',
      href: '/mlb/spread',
    },
    {
      title: 'Totals',
      href: '/mlb/total',
    },
    {
      title: 'Props',
      href: '/mlb/props',
      // extneral: true,
      target: '_blank',
    },
  ],
  nhl: [
    {
      title: 'Moneylines',
      href: '/nhl/moneyline',
    },
    {
      title: 'Spreads',
      href: '/nhl/spread',
    },
    {
      title: 'Totals',
      href: '/nhl/total',
    },
  ],
  ncaaf: [
    {
      title: 'Moneylines',
      href: '/ncaaf/moneyline',
    },
    {
      title: 'Spreads',
      href: '/ncaaf/spread',
    },
    {
      title: 'Totals',
      href: '/ncaaf/total',
    },
  ],
  nfl: [
    {
      title: 'Moneylines',
      href: '/nfl/moneyline',
    },
    {
      title: 'Spreads',
      href: '/nfl/spread',
    },
    {
      title: 'Totals',
      href: '/nfl/total',
    },
  ],
  mlbProps: [
    {
      title: 'SP Strikeouts',
      href: '/mlb/props',
    },
  ],
};

// };

export default pages;