// src/components/NavTabs.js
// import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Custom styles using styled from @mui/system
const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none', // Prevent automatic capitalization
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(15),
  // marginRight: theme.spacing(2),
  // '&.Mui-selected': {
  //   color: theme.palette.primary.main,
  // },
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  // '&:focus': {
  //   outline: `2px solid ${theme.palette.primary.main}`,
  // },
}));

const NavTabs = ({ routes }) => {
  const location = useLocation();
  const currentTab = location.pathname;

  return (
    <StyledTabs value={currentTab} centered>
      {routes.map((route, index) => (
        <StyledTab
          key={index}
          label={capitalizeFirstLetter(route.label)} // Capitalize the label
          value={route.path}
          component={Link}
          to={route.path}
          aria-label={route.label} // Add ARIA label for accessibility

        />
      ))}
    </StyledTabs>
  );
};

export default NavTabs;